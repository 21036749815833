import Head from "next/head";
import React, { useState } from "react";
import LoginTemplate from "@/templates/LoginTemplate";
import { candidateSession, candidateLogin } from "services/candidate.service";
import {
  isUnauthorizedRequest,
  setAuth,
  setCompanyId,
  setCompanyTimeZone,
} from "services/identity.service";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "yup-phone";
import { yupValidator } from "lib/yup-validator";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { useFlags } from "flagsmith/react";
const CandidateLoginIndex = () => {
  const router = useRouter();
  const flag = useFlags(["candidate_email_login", "whatsapp_consent", 'login_page_redesign']);
  const [apiError, setApiError] = useState([]);
  const yupValidator = (schema) => async (data, context) => {
    try {
      const values = { ...data, loginType: context.parent.loginType };
      await schema.validate(values, { abortEarly: false });
      return { isValid: true };
    } catch (errors) {
      return { isValid: false, errors };
    }
  };
  const checkCandidateStatus = (candidate) =>{
    if(candidate.status==='Offer Accepted' || candidate.status==='Joined') return false;
    return true;
  }
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        mobileNumber: yup.string().when("loginType", {
          is: "mobileNumber",
          then: yup
            .string()
            .phone(null, null, "Please enter a valid phone number")
            .required("A phone number is required"),
          otherwise: yup.string().nullable().notRequired(),
        }),
        loginType: yup.string(),
        whatsappConsent: yup.boolean(),
        emailId: yup.string().when("loginType", {
          is: "email",
          then: yup
            .string()
            .email("Please enter a valid e-mail")
            .max(255)
            .required("Email is required"),
          otherwise: yup.string().nullable().notRequired(),
        }),
      })
    ),
    defaultValues: {
      loginType: 'mobileNumber',
      whatsappConsent: true,
    },
  });

  const countryOptionsList = [];
  getCountries().forEach((country) => {
    countryOptionsList.push({
      value: country,
      label: en[country] + " +" + getCountryCallingCode(country),
      countryCode: getCountryCallingCode(country),
    });
  });
  const [country, setCountry] = useState({
    value: "IN",
    label: "+91",
    countryCode: 91,
  });

  const [loginDetails, setLoginDetails] = flag.candidate_email_login.enabled
    ? useState({
      step: flag.login_page_redesign.enabled ? 1 : 0,
      mobileNumber: "",
      countryCode: 91,
      emailId: "",
      loginType: "",
      whatsappConsent: true,
    })
    : useState({
      step: 1,
      mobileNumber: "",
      countryCode: 91,
      emailId: "",
      loginType: "",
      whatsappConsent: true,
    });

  const onClickBackArrow = () => {
    setLoginDetails({
      ...loginDetails,
      step: 0,
    });
  };
  const handleLoginOptionSelect = (type) => {
    setValue("loginType", type);
    setLoginDetails({
      ...loginDetails,
      step: 1,
    });
  };
  const handleGetOTP = async (data) => {
    const { loginType, mobileNumber, emailId } = getValues();
    const res = await candidateLogin(
      mobileNumber,
      country.countryCode,
      null,
      emailId,
      loginType
    );
    if (res.status) {
      if (checkCandidateStatus(res.entity)) {
        setApiError('Candidate does not exists')
     }
     else {
        setLoginDetails({
          step: 2,
          mobileNumber: mobileNumber,
          countryCode: country.countryCode,
          emailId: emailId,
          loginType: loginType,
          whatsappConsent: res.entity.whatsappConsent === true ? true : false,
        });
        setApiError([]);
      }
    } else {
      setApiError(res.message);
    }
  };

  const handleLogin = async (otp) => {
    if (otp.length == 4) {
      const { whatsappConsent } = getValues();
      const res = await candidateSession(
        loginDetails.mobileNumber,
        loginDetails.emailId,
        loginDetails.loginType,
        otp,
        loginDetails.countryCode,
        null,
        flag.whatsapp_consent.enabled ? whatsappConsent : null
      );
      if (res.status) {
        setAuth(res.entity);
        //  console.log(`${compant}`);
        setCompanyId(res.entity?.company.id);
        setCompanyTimeZone(res.entity?.company.timeZone);

        router.push({
          pathname: "[company]/dashboard",
          query: {
            company: res.entity.company.slug,
          },
        });
      } else {
        setApiError(res.message);
      }
    }
  };

  const resendOtp = async () => {
    const { loginType, mobileNumber, emailId } = getValues();
    await candidateLogin(
      mobileNumber,
      country.countryCode,
      null,
      emailId,
      loginType
    );
  };
  const handleCountryChange = (country) => {
    const val = "+" + getCountryCallingCode(country.value);
    setCountry({
      value: country.val,
      label: val,
      countryCode: parseInt(getCountryCallingCode(country.value)),
    });
  };

  const editMobile = () => {
    setApiError([]);
    setLoginDetails((prevState) => {
      return { ...prevState, step: 1 };
    });
  };

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <LoginTemplate
        handleSubmit={handleSubmit}
        control={control}
        register={register}
        setValue={setValue}
        country={country}
        getValues={getValues}
        handleCountryChange={handleCountryChange}
        countryOptionsList={countryOptionsList}
        handleLoginOptionSelect={handleLoginOptionSelect}
        isAdmin={false}
        loginDetails={loginDetails}
        apiError={apiError}
        handleGetOTP={handleGetOTP}
        handleLogin={handleLogin}
        resendOtp={resendOtp}
        editMobile={editMobile}
        setError={setApiError}
        onClickBackArrow={onClickBackArrow}
      />
    </>
  );
};
export default CandidateLoginIndex;
