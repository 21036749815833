import * as HttpService from "./http.service";
import { getCompanyId } from "./identity.service";
import {
  ADD_BULK_CANDIDATES_FROM_CSV_URL,
  ADD_CANDIATE_URL,
  ADD_CANDIDATE_NEWSFEED_URL,
  ADD_RECRUITER_SCORE,
  CANDIDATE_CONFIDENCE_SCORE,
  CANDIDATE_CONVERTED_STATUS,
  CANDIDATE_ENGAGEMENT_SCORE,
  CANDIDATE_LOGIN,
  CANDIDATE_SESSION,
  ENGAGEMENT_TIME_URL,
  GET_CANDIATE_URL,
  GET_CANDIDATES_TASK_ERRORS,
  GET_CANDIDATE_NEWSFEED_URL,
  GET_CANDIDATE_REPORT,
  GET_CANDIDATE_TOUCHPOINT_INSTANCES,
  UPDATE_CANDIATE_STATUS_URL,
  UPDATE_CANDIATE_URL,
  UPDATE_CANIDATE_WHATSAPP_CONSENT,
  VALIDATE_CANDIDATES_FROM_CSV_URL,
} from "./url.service";

export const candidateLogin = (mobileNumber, countryCode, company, emailId, loginType) => {
  return HttpService.postWithOutAuth(CANDIDATE_LOGIN, {
    mobileNumber: mobileNumber,
    countryCode: countryCode,
    company,
    emailId,
    loginType
  });
};
export const candidateSession = (mobileNumber, emailId, loginType, otp, countryCode, company, whatsappConsent) => {
  return HttpService.postWithOutAuth(CANDIDATE_SESSION, {
    mobileNumber,
    emailId,
    loginType,
    otp,
    countryCode,
    company,
    whatsappConsent
  });
};
export const getCandidateTouchPointInstances = (
  candidateId,
  token,
  search = "",
  touchPointStatus = "",
  offset = 0,
  sort,
  limit
) => {
  return HttpService.getWithAuth(
    GET_CANDIDATE_TOUCHPOINT_INSTANCES(
      candidateId,
      search,
      touchPointStatus,
      offset,
      sort,
      limit
    ),
    token
  );
};

export const addCandidate = (companyId, candidateDetails) => {
  return HttpService.postWithAuth(ADD_CANDIATE_URL(companyId), {
    ...candidateDetails,
  });
};

export const getCandidates = (
  companyId,
  search = "",
  offset,
  sort,
  filters = ""
) => {
  return HttpService.getWithAuth(
    ADD_CANDIATE_URL(companyId, search, offset, sort, filters)
  );
};

export const getCandidateReport = (
  companyId
) => {
  return HttpService.getWithAuth(
    GET_CANDIDATE_REPORT(companyId)
  );
};

export const updateCandidate = (candidateId, candidateDetails) => {
  return HttpService.putWithAuth(UPDATE_CANDIATE_URL(candidateId), {
    ...candidateDetails,
  });
};

export const updateCandidateStatus = (candidateId, status) => {
  return HttpService.putWithAuth(UPDATE_CANDIATE_STATUS_URL(candidateId), {
    status,
  });
};

export const getCandidateById = (candidateId) => {
  return HttpService.getWithAuth(GET_CANDIATE_URL(candidateId));
};

export const sendEngagementActivity = (engagementDetails) => {
  return HttpService.sendBeacon(ENGAGEMENT_TIME_URL, engagementDetails);
};

export const getConfidenceScore = () => {
  const companyId = getCompanyId();
  return HttpService.getWithAuth(CANDIDATE_CONFIDENCE_SCORE(companyId));
};

export const getCandidateConvertedStatus = (filters = "") => {
  const companyId = getCompanyId();
  return HttpService.getWithAuth(
    CANDIDATE_CONVERTED_STATUS({ companyId, filters })
  );
};

export const getCandidatesAverageEngagementScore = (filters = "", offerAccepted) => {
  const companyId = getCompanyId();
  return HttpService.getWithAuth(
    CANDIDATE_ENGAGEMENT_SCORE({ companyId, filters, offerAccepted })
  );
};



export const validateCandidatesFromCSV = (companyId, csvData) => {
  return HttpService.postWithAuth(VALIDATE_CANDIDATES_FROM_CSV_URL(companyId), {
    csvData,
  });
};

export const addCandidatesFromCSV = (companyId, csvData) => {
  return HttpService.postWithAuth(ADD_BULK_CANDIDATES_FROM_CSV_URL(companyId), {
    csvData,
  });
};

export const addRecruiterScoreToCandidate = (companyId, details) => {
  return HttpService.postWithAuth(ADD_RECRUITER_SCORE(companyId), { ...details })
}

export const addCandidateNewsFeedEngagement = (companyId, { candidateId, newsFeedId }) => {
  return HttpService.postWithAuth(ADD_CANDIDATE_NEWSFEED_URL(companyId), { candidateId, newsFeedId });
};

export const getNewsFeedEngagementByCandidateId = (companyId, candidateId) => {
  return HttpService.getWithAuth(GET_CANDIDATE_NEWSFEED_URL(companyId, candidateId));
};

export const changeWhatsappConsent = (candidateId, whatsappConsent) => {
  return HttpService.putWithAuth(UPDATE_CANIDATE_WHATSAPP_CONSENT(candidateId), {
    whatsappConsent
  })
}